@import '../../../styles/customMediaQueries.css';

.root {
  position: relative;
  width: auto;
  height: auto;
  border: 0;
  padding: 0;

  &:hover {
    & .priceLabel,
    & .caret {
      cursor: pointer;

      background-color: transparent;
    }
  }
}

.priceLabel {
  composes: h5 from global;
  /**
   * Since caret is absolutely positioned,
   * label must have relative to be included to the same rendering layer
   */
  position: relative;
  background-image: url("data:image/svg+xml,%3Csvg fill='%23EA4335' height='36px' width='36px' version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' viewBox='0 0 23.04 23.04' enable-background='new 0 0 512 512' xml:space='preserve'%3E%3Cpath d='M11.52 0C6.719 0 2.88 3.838 2.88 8.64c0 1.66 0.495 2.943 1.355 4.244l6.376 9.675c0.193 0.292 0.526 0.481 0.909 0.481s0.72 -0.193 0.909 -0.481l6.376 -9.675C19.665 11.583 20.16 10.3 20.16 8.64 20.16 3.838 16.322 0 11.52 0m0 13.437c-2.65 0 -4.801 -2.151 -4.801 -4.806S8.869 3.825 11.52 3.825s4.801 2.151 4.801 4.806S14.17 13.437 11.52 13.437'/%3E%3C/svg%3E");

  &.signedUp {
    background-image: url("data:image/svg+xml,%3Csvg fill='%23EA4335' height='36px' width='36px' version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' viewBox='0 0 23.04 23.04' enable-background='new 0 0 512 512' xml:space='preserve'%3E%3Cpath d='M11.52 0C6.719 0 2.88 3.838 2.88 8.64c0 1.66 0.495 2.943 1.355 4.244l6.376 9.675c0.193 0.292 0.526 0.481 0.909 0.481s0.72 -0.193 0.909 -0.481l6.376 -9.675C19.665 11.583 20.16 10.3 20.16 8.64 20.16 3.838 16.322 0 11.52 0m0 13.437c-2.65 0 -4.801 -2.151 -4.801 -4.806S8.869 3.825 11.52 3.825s4.801 2.151 4.801 4.806S14.17 13.437 11.52 13.437'/%3E%3C/svg%3E");
  }

  &.boothConfigured {
    background-image: url("data:image/svg+xml,%3Csvg fill='%23000000' height='36px' width='36px' version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' viewBox='0 0 23.04 23.04' enable-background='new 0 0 512 512' xml:space='preserve'%3E%3Cpath d='M11.52 0C6.719 0 2.88 3.838 2.88 8.64c0 1.66 0.495 2.943 1.355 4.244l6.376 9.675c0.193 0.292 0.526 0.481 0.909 0.481s0.72 -0.193 0.909 -0.481l6.376 -9.675C19.665 11.583 20.16 10.3 20.16 8.64 20.16 3.838 16.322 0 11.52 0m0 13.437c-2.65 0 -4.801 -2.151 -4.801 -4.806S8.869 3.825 11.52 3.825s4.801 2.151 4.801 4.806S14.17 13.437 11.52 13.437'/%3E%3C/svg%3E");
  }

  &.onboarded {
    background-image: url("data:image/svg+xml,%3Csvg fill='%2300004d' height='36px' width='36px' version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' viewBox='0 0 23.04 23.04' enable-background='new 0 0 512 512' xml:space='preserve'%3E%3Cpath d='M11.52 0C6.719 0 2.88 3.838 2.88 8.64c0 1.66 0.495 2.943 1.355 4.244l6.376 9.675c0.193 0.292 0.526 0.481 0.909 0.481s0.72 -0.193 0.909 -0.481l6.376 -9.675C19.665 11.583 20.16 10.3 20.16 8.64 20.16 3.838 16.322 0 11.52 0m0 13.437c-2.65 0 -4.801 -2.151 -4.801 -4.806S8.869 3.825 11.52 3.825s4.801 2.151 4.801 4.806S14.17 13.437 11.52 13.437'/%3E%3C/svg%3E");
  }

  &.activeEngaged {
    background-image: url("data:image/svg+xml,%3Csvg fill='%23A275F0' height='36px' width='36px' version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' viewBox='0 0 23.04 23.04' enable-background='new 0 0 512 512' xml:space='preserve'%3E%3Cpath d='M11.52 0C6.719 0 2.88 3.838 2.88 8.64c0 1.66 0.495 2.943 1.355 4.244l6.376 9.675c0.193 0.292 0.526 0.481 0.909 0.481s0.72 -0.193 0.909 -0.481l6.376 -9.675C19.665 11.583 20.16 10.3 20.16 8.64 20.16 3.838 16.322 0 11.52 0m0 13.437c-2.65 0 -4.801 -2.151 -4.801 -4.806S8.869 3.825 11.52 3.825s4.801 2.151 4.801 4.806S14.17 13.437 11.52 13.437'/%3E%3C/svg%3E");
  }

  &.tradeShow {
    background-image: url("data:image/svg+xml,%3Csvg fill='%234AA6F8' height='36px' width='36px' version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' viewBox='0 0 23.04 23.04' enable-background='new 0 0 512 512' xml:space='preserve'%3E%3Cpath d='M11.52 0C6.719 0 2.88 3.838 2.88 8.64c0 1.66 0.495 2.943 1.355 4.244l6.376 9.675c0.193 0.292 0.526 0.481 0.909 0.481s0.72 -0.193 0.909 -0.481l6.376 -9.675C19.665 11.583 20.16 10.3 20.16 8.64 20.16 3.838 16.322 0 11.52 0m0 13.437c-2.65 0 -4.801 -2.151 -4.801 -4.806S8.869 3.825 11.52 3.825s4.801 2.151 4.801 4.806S14.17 13.437 11.52 13.437'/%3E%3C/svg%3E");
  }

  /* Font */
  composes: p from global;
  font-weight: var(--fontWeightBold);
  margin-top: 0;
  margin-bottom: 0;
  height: 36px;
  width: 36px;
  padding: 6px 14px;
  font-size: 12px;
  color: var(--colorGrey700);
  /* Overwrite dimensions from font styles */
  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}
.noPriceSetLabel {
  /* width: 30px;
  height: 30px; */
}

.mapLabelActive {
  /* Same as hover */
  /* background-color: var(--marketplaceColor); */
  border-color: var(--marketplaceColor);
  color: var(--colorWhite);
  /* box-shadow: var(--boxShadowPopup); */
}

.caretShadow {
  /* Caret / arrow dimensions and position */
  /* width: 6px;
  height: 6px;
  position: absolute;
  bottom: -3px;
  left: 50%;
  margin-left: -3px;
  transform: rotate(45deg); */

  /* Caret should have same box-shadow as label */
  /* box-shadow: var(--boxShadowPopupLight); */
}

.caret {
  /* Caret / arrow dimensions and position */
  /* width: 6px;
  height: 6px;
  position: absolute;
  bottom: -3px;
  left: 50%;
  margin-left: -3px;
  transform: rotate(45deg);

  transition: var(--transitionStyleButton); */

  /* Caret should have same bg-color and border as label */
  /* background-color: var(--colorWhite);
  border-right-style: solid;
  border-right-color: var(--colorGrey100);
  border-right-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: var(--colorGrey100);
  border-bottom-width: 1px; */
}

.caretActive {
  /* Same as hover */
  background-color: var(--marketplaceColor);
  border-color: var(--marketplaceColor);
}

.price {
  position: absolute;
  top: 6px;
  line-height: 0;
  left: 0;
  right: 0;
  background: #fff;
  border-radius: 50%;
  width: 15px;
  height: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  font-size: 12px;
}

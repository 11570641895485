@import '../../../styles/customMediaQueries.css';

.root {
  /* Size from content */
  position: relative;
  width: auto;
  height: auto;
  padding: 0;
  border: 0;

  &:hover {
    & .details,
    & .caret {
      cursor: pointer;
      background-color: transparent;
    }
  }
}

.details {
  /**
   * Since caret is absolutely positioned,
   * label must have relative to be included to the same rendering layer
   */
  position: relative;
  background-image: url("data:image/svg+xml,%3Csvg fill='%23EA4335' height='36px' width='36px' version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' viewBox='0 0 23.04 23.04' enable-background='new 0 0 512 512' xml:space='preserve'%3E%3Cpath d='M11.52 0C6.719 0 2.88 3.838 2.88 8.64c0 1.66 0.495 2.943 1.355 4.244l6.376 9.675c0.193 0.292 0.526 0.481 0.909 0.481s0.72 -0.193 0.909 -0.481l6.376 -9.675C19.665 11.583 20.16 10.3 20.16 8.64 20.16 3.838 16.322 0 11.52 0m0 13.437c-2.65 0 -4.801 -2.151 -4.801 -4.806S8.869 3.825 11.52 3.825s4.801 2.151 4.801 4.806S14.17 13.437 11.52 13.437'/%3E%3C/svg%3E");
  /* Font */
  composes: p from global;
  font-weight: var(--fontWeightBold);
  margin-top: 0;
  margin-bottom: 0;
  height: 36px;
  width: 36px;
  padding: 6px 14px;
  font-size: 12px;
  /* Coloring */
  /* background-color: var(--colorWhite); */
  color: var(--colorGrey700);

  /* Borders */
  /* border-radius: 19px;
  border-style: solid;
  border-color: var(--colorGrey100);
  border-width: 1px;
  box-shadow: var(--boxShadowPopupLight); */

  transition: var(--transitionStyleButton);

  /* Overwrite dimensions from font styles */
  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.count {
  position: absolute;
  top: 4px;
  line-height: 0;
  left: 0;
  right: 0;
  background: #fff;
  border-radius: 50%;
  width: 18px;
  height: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  font-size: 12px;
}

.detailsActive {
  /* Same as hover */
  /* background-color: var(--marketplaceColor); */
  border-color: var(--marketplaceColor);
  /* color: var(--colorWhite);
  box-shadow: var(--boxShadowPopup); */
}

.caretShadow {
  /* Caret / arrow dimensions and position */
  /* width: 6px;
  height: 6px;
  position: absolute;
  bottom: -3px;
  left: 50%;
  margin-left: -3px;
  transform: rotate(45deg); */

  /* Caret should have same box-shadow as label */
  /* box-shadow: var(--boxShadowPopupLight); */
}

.caret {
  /* Caret / arrow dimensions and position */
  /* width: 6px;
  height: 6px;
  position: absolute;
  bottom: -3px;
  left: 50%;
  margin-left: -3px;
  transform: rotate(45deg); */

  /* Caret should have same bg-color as label */
  /* background-color: #ea4335; */

  transition: var(--transitionStyleButton);
}

.caretActive {
  /* Same as hover */
  background-color: var(--marketplaceColor);
  border-color: var(--marketplaceColor);
}

@import '../../../styles/customMediaQueries.css';

.root {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: #fff;
}

.filtersWrapper {
  @media (--viewportMedium) {
    display: grid;
    grid-gap: 15px 30px;
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: auto;
  }

  @media (--viewportLarge) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (min-width: 1200px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (--viewportXLarge) {
    grid-template-columns: repeat(2, 1fr);
  }
  & > div {
    padding-top: 0;
    border-bottom: 1px solid #ddd;
    padding-bottom: 6px;
    & > div:nth-child(2) {
      border-top: 1px solid #ddd;
      padding-right: 10px;
      padding-top: 10px;
      margin-top: 10px;
      max-height: 200px;
      overflow-y: auto;
      &::-webkit-scrollbar {
        height: 3px;
        width: 5px;
        border-radius: 10px;
      }

      &::-webkit-scrollbar-track {
        background: #f1f1f1;
        border-radius: 10px;
      }

      &::-webkit-scrollbar-thumb {
        background: #888;
        border-radius: 10px;
      }
      & span {
        font-size: 14px;
      }
      & svg {
        height: 18px;
        width: 18px;
      }
      /* box-shadow: 0px 3px 6px 0px #0000004d; */
    }
  }
}

.footer {
  display: flex;
  margin-top: 8px;
}

.filterButton {
  /* Position and dimensions */
  display: inline;
  padding: 0;

  /* Borders */
  border: none;

  /* Text size should be inherited */
  text-decoration: none;

  font-weight: var(--fontWeightMedium);
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0;
  /* margin-top + n * line-height + margin-bottom => x * 6px */
  margin-top: 10px;
  margin-bottom: 8px;

  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }

  @media (--viewportMedium) {
    line-height: 16px;
    /* margin-top + n * line-height + margin-bottom => x * 8px */
    margin-top: 10px;
    margin-bottom: 14px;
  }
}
.resetAllButton {
  composes: filterButton;
  color: var(--colorGrey300);

  /* Layout */
  margin: 0 auto 0 0;

  /* Override button styles */
  outline: none;

  &:focus,
  &:hover {
    color: var(--colorGrey700);
  }
}

.cancelButton {
  composes: filterButton;
  color: var(--colorGrey300);

  /* Layout */
  margin: 0 24px 0 24px;

  /* Override button styles */
  outline: none;

  &:focus,
  &:hover {
    color: var(--colorGrey700);
  }
}

.applyButton {
  composes: filterButton;
  color: var(--marketplaceColor);

  /* Layout */
  margin: 0;

  /* Override button styles */
  outline: none;

  &:focus,
  &:hover {
    color: var(--marketplaceColorDark);
  }
}
